/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import { useCallback, useState } from 'react';
import { Button, FullHeightColumn, Switch } from '../components';
import { useDispatch } from 'react-redux';
import { updateUser } from '../state/session';
import { useTranslate } from '../utils/getLanguage';

export default () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [emailChecked, setEmailChecked] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOptIn = useCallback(() => {
    setSubmitting(true);
    dispatch(
      updateUser({ emailMarketingPermission: emailChecked, smsMarketingPermission: smsChecked, marketingOptedIn: true })
    );
  }, [dispatch, emailChecked, smsChecked]);

  return (
    <FullHeightColumn>
      <Styled.h2 sx={{ mt: 0, mb: 3 }}>{translate('account.notifications')}</Styled.h2>
      <p>{translate('account.marketingPermissions')}</p>
      <Box sx={{ mb: 2 }}>
        <Switch defaultChecked={emailChecked} onChange={() => setEmailChecked(!emailChecked)}>
          {translate('account.emailMarketingPermission')}
        </Switch>
      </Box>
      <Switch defaultChecked={smsChecked} onChange={() => setSmsChecked(!smsChecked)}>
        {translate('account.smsMarketingPermission')}
      </Switch>
      <div sx={{ mt: 4 }}>
        <Button sx={{ px: 4 }} onClick={handleOptIn} disabled={submitting}>
          {translate('account.continue')}
        </Button>
      </div>
    </FullHeightColumn>
  );
};
